<template>
  <div class="info">
    <div class="cont">
      <div>
        <Form :model="formItem" :rules="ruleValidate" ref="formValidate" :label-width="140">
          <FormItem :label="$t('trainingcamp_exam_create_info_name')" prop="name">
            <Input v-model="formItem.name" placeholder="题库名称" style="width: 300px"></Input>
          </FormItem>
          <FormItem label="备注" prop="desc">
            <Input v-model="formItem.desc" type="textarea" placeholder="请输入备注" style="width: 300px"></Input>
          </FormItem>
        </Form>
      </div>
    </div>
    <div class="bot">
      <Button @click="save" :loading="saveLoading" >{{ $t('trainingcamp_exam_create_save') }}</Button>
      <Button type="primary" @click="next" :loading="nextLoading" >{{ $t('trainingcamp_exam_create_next') }}</Button>
    </div>
  </div>
</template>

<script>
export default {
  name: "info.vue",
  data(){
    return {
      formItem:{
        name:'',
        desc:''
      },
      ruleValidate: {
        name: [
          { required: true, message: '请输入题库名称', trigger: 'blur' }
        ],
      },
      saveLoading:false,
      nextLoading:false,
      info:{}
    }
  },
  props:{
    groupId:{
      type:String,
      default:'',
    },
    groupInfo:{
      type:Object,
      default:()=>{
        return {}
      },
    }
  },
  watch:{
    groupInfo:{
      handler(newV,oldV){
        this.info = newV;
        this.initData();
      },
      immediate:true,
    }
  },
  methods:{
    initData(){
      this.formItem.name = this.info.name;
      this.formItem.desc = this.info.desc;
    },
    submit(type){ //提交数据
      this.$refs.formValidate.validate((valid) => {
        if(valid){
          let params = {
            name:this.formItem.name,
            desc:this.formItem.desc,
            id:this.groupId
          };
          if(type == 'next'){   //下一步
            this.nextLoading = true;
          }else if(type == 'save'){ //保存并退出
            this.saveLoading = true;
          }
          this.api.dataset.groupUpdate(params).then((res)=>{
            if(type == 'next'){   //下一步
              this.nextLoading = false;
              this.$emit('next',);
            }else if(type == 'save'){ //保存并退出
              this.saveLoading = false;
              this.$emit('save');
            }
          })
        }
      })
    },
    next(){
      this.submit('next');
    },
    save(){
      this.submit('save');
    },
  }
}
</script>

<style scoped lang="scss">
.info{
  .cont{
    .tit-upset{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .tit-upset-switch{
        margin-top: 5px;
      }
      >span{
        padding-left: 10px;
      }
      >div{
        margin-top: 5px;
        margin-left: 20px;
        line-height: 20px;
        color:#808695;
      }
    }
    .tag{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      height: 40px;
      line-height: 40px;
      >div{
        margin-right: 20px;
        width: 100px;
        border:1px solid #dcdee2;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
      }
      >div.active{
        background-color: #2d8cf0;
        color: #FFFFFF;
      }
    }
  }
  .bot{
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #dcdee2;
    display: flex;
    justify-content: flex-end;

    >button{
      margin-left: 10px;
    }
  }
}
</style>
