<template>
<div class="upload-excel">
  <div class="tit">模板导入试题</div>
  <div class="tip">请先下载格式模板，参考模板，调整自己的文档格式，再进行上传</div>
  <Upload
      type="drag"
      :action="constant.URL + '/uploadfile/upload'"
      :headers="{
                  'access-token':Cookies.get(constant.tokenName)
                }"
      :data="{
                  type:0
                }"
      accept=".csv"
      :format="['csv']"
      :on-success="fileSuccess"
      :show-upload-list="false"
      :on-format-error="formatError"
      class="upload-cont"
  >
    <div class="upload-cont-info">
      <img src="../../../../assets/images/icon/excel.png" />
      <p>{{fileName ? fileName : 'Excel模板导入'}}</p>
      <div class="upload-cont-info-message">
        <p>1. 支持图片上传，图片放在单元格以内。</p>
        <p>1. 支持图片上传，图片放在单元格以内。</p>
        <p>1. 支持图片上传，图片放在单元格以内。</p>
      </div>
    </div>
  </Upload>
  <div class="upload-btn">
    <Button @click="downLoadExcel">Excel模板下载</Button>
    <Upload :action="constant.URL + '/uploadfile/upload'"
            :headers="{
                  'access-token':Cookies.get(constant.tokenName)
                }"
            :data="{
                  type:0
                }"
            accept=".csv"
            :on-success="fileSuccess"
            :show-upload-list="false"
    >
      <Button icon="ios-cloud-upload-outline" type="primary">上传题库</Button>
    </Upload>
  </div>
  <div class="tip">点击按钮选择试题文件，或拖拽试题文件到此区域</div>
</div>
</template>

<script>
import util from '../../../../utils/tools.js';
import Cookies from "js-cookie";
export default {
  name: "uploadExcel.vue",
  data(){
    return {
      Cookies:Cookies,
      url:'https://public.saas.edu360.cn/template/data_template.csv',
      loading:false,
      saveLoading:false,
      uploadfileId:'',
      fileName:'',
    }
  },
  props:{
    groupId:{
      type:String,
      default:'',
    }
  },
  methods:{
    downLoadExcel(){
      util.downloadFile(this,this.url);
    },
    fileSuccess(response,file, fileList){  //上传文件成功
      console.log(response.data.info,'dddd')
      this.uploadfileId = response.data.info.id;
      this.$emit('uploadFile',this.uploadfileId);
      this.fileName = response.data.info.name;
      this.$Message.success('上传文件成功');
    },
    formatError(){
      this.$Message.warning('请上传Excel文件');
    }
  }
}
</script>

<style scoped lang="scss">
.upload-excel{
  font-size: 14px;
  .tit{
    margin: 20px 0 10px 0;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .tip{
    margin-bottom: 20px;
    text-align: center;
  }
  .upload-cont{
    margin: 0 auto;
    width: 500px;

    .upload-cont-info{
      >img{
        margin-top: 30px;
        width: 140px;
      }
      .upload-cont-info-message{
        margin: 20px 0;
        padding-left: 20px;
        text-align: left;
        line-height: 28px;
      }
    }
  }
  .upload-btn{
    margin: 20px auto;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    >button{
      margin-right: 20px;
    }
  }
}
</style>
